import React from 'react';
import './warningModal.css';

interface WarningModalProps {
    show: boolean;
    message: string;
    onClose: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({ show, message, onClose }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modalOverlay">
            <div className="modalContent">
                <h2>Achtung!</h2>
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default WarningModal;