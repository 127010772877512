import './InputElement.css';
import { useRegistration } from '../../../context/registrationContext';
import { RegistrationType } from '../../../types/registration';
import React from 'react';

interface InputElementProps {
    id: string;
    label: string;
    type: string;
    placeholder?: string;
    step: keyof RegistrationType;
}

const InputElement: React.FC<InputElementProps> = ({ id, label, type, placeholder, step }) => {
    const { registration, updateRegistration } = useRegistration();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        updateRegistration({
            [`${step}.${id}`]: event.target.value
        });
    };

    // Safely access the nested value
    const value = registration[step] && typeof registration[step] === 'object'
        ? (registration[step] as Record<string, any>)[id] || ''
        : '';

    return (
        <div key={id} className='inputElement'>
            <div className='inputElementLabel'>
                {label}:
            </div>
            {type === 'multiline' ? (
                <textarea 
                    className='inputElementInput multiline' 
                    value={value}
                    onChange={handleChange} 
                    placeholder={placeholder ?? ''}
                />
            ) : (
                <input 
                    className='inputElementInput' 
                    value={value}
                    onChange={handleChange} 
                    type={type} 
                    placeholder={placeholder ?? ''}
                />
            )}
        </div>
    );
};

export default InputElement;